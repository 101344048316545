import React, { useState, useEffect } from "react";
import "./index.css"
import {Helmet} from "react-helmet";

const CaseStudy = () => {
    const [blogdata, setblogdata, data] = useState({});
    const [blogdetail, setblogdetail, detail] = useState({});
    const [showPopup, setShowPopup] = useState(false);
        const [formData, setFormData] = useState({
            name: "",
            email: "",
          });
        
          const [errors, setErrors] = useState({});
          const validateForm = () => {
            let newErrors = {};
        
            if (!formData.name.trim()) {
              newErrors.name = "Name is required";
            }
        
            if (!formData.email.trim()) {
              newErrors.email = "Email is required";
            } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
              newErrors.email = "Invalid email format";
            }
        
            setErrors(newErrors);
            return Object.keys(newErrors).length === 0; // Return true if no errors
          };
          const handleSubmit = (e) => {
            e.preventDefault();
            if (validateForm()) {
                window.open("https://global.staffwiz.com/video-page-page?email="+formData.email, "_blank"); // Opens link in a new tab
                setShowPopup(false);
            }
          };
        
          const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
          };

    // useEffect(async () => { 
    //     try {
    //         const res = await fetch("https://www.sellmymiles.com/staffwiz/wp-json/wp/v2/posts?categories=3&_embed&per_page=100", {
    //             method: "GET",
    //             statusCode: 200,
    //             headers: {
    //                 'Content-Type':'application/json',
    //             },
                
    //         })

    //         let data = await res.json(); console.log(data);
    //         if (data && data.length)
    //             setblogdata(data); console.log(data);
    //     }
    //     catch (e) {
    //         console.log(e)
    //     }
    // }, []);
const loadblogdata = async () => {
        let catagiorydata;

        const res = await fetch("https://www.sellmymiles.com/staffwiz/wp-json/wp/v2/posts?categories=3&_embed&per_page=100", {
            method: "GET",
            statusCode: 200,
            headers: {
                 'Content-Type':'application/json',
            },
        })
        let data = await res.json();
        if (data) {
            setblogdata(data);
            console.log(blogdata);
        }
    }
    const loadblogdetail = async () => {
        let catagiorydata;

        const res = await fetch("https://www.sellmymiles.com/staffwiz/wp-json/wp/v2/posts?_embed&per_page=5", {
            method: "GET",
            statusCode: 200,
            headers: {
                 'Content-Type':'application/json',
            },
        })
        let data = await res.json();
        if (data) {
            setblogdetail(data);
            console.log(blogdetail);
        }
    }
    useEffect(() => {
        loadblogdata();
        loadblogdetail();
        const timer = setTimeout(() => {
            setShowPopup(true);
          }, 5000); // Show after 10 seconds
      
         return () => clearTimeout(timer); // Cleanup timer
    }, []);
    const decodeHtmlCharCodes = (str) => {
        return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
            return String.fromCharCode(charCode);
        });
    }
   
// Close popup when clicking outside
const handleOutsideClick = (event) => {
    if (event.target.classList.contains("popup-overlay")) {
      setShowPopup(false);
    }
  };

    return (
        <>
         <Helmet>
            <title>Case Study | StaffWiz</title>
            <meta name="description" content="Case Study Page" />
        </Helmet>
        {showPopup && (
        <div className="popup-overlay" onClick={handleOutsideClick}>
        <div className="popup-box">
          <div className="pop-left">
           <img className="img-fluid" src="assets/images/popImg.png"></img>
          </div>
          <div className="pop-right">
            <h1>Cut Labor Costs by 70%</h1>
            <h3>Without Adding Complexity</h3>
          <p>Discover the proven strategy <b>Fortune 500 companies</b> use to scale efficiently.</p>
          <ul>
            <li>Reduce costs while maintaining quality</li>
            <li>Leverage expert workforce solutions</li>
            <li>Scale faster with streamlined operations</li>
          </ul>
          <input type="text" name="name" value={formData.name}
            onChange={handleChange} className={errors.name ? "inpt error-border" : "inpt"} placeholder="Your Name*" />
          <input type="text" name="email" className={errors.email ? "inpt error-border" : "inpt"} value={formData.email}
            onChange={handleChange} placeholder="Your Company Email *" />
          <button className="close-btn" onClick={handleSubmit}>
           GET ACCESS NOW
          </button>
          </div>
        </div>
      </div>
      )}
            <section className="blog">
                <h1>Case Study</h1>

                <div className="container">
                    <div className="row">
                    <div className="col-12 col-md-9 col-lg-9">
                        {Array.isArray(blogdata) && blogdata?.map((data) => {
                            return (
                                <div className="col-12 col-md-6 col-lg-4" style={{float:'left'}}>
                                    <a href={data.slug}>
                                        <div className="card">
                                            <img className="sm-img" src={data._embedded['wp:featuredmedia']['0'].source_url} style={{height:'250px'}}></img>
                                            <div className="card-info">
                                                <h2 style={{fontSize:'15px'}}>{decodeHtmlCharCodes(data.title.rendered)}</h2>
                                                <h6>{data.date.split("T")[0]}</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            )
                        })}

</div>
<div className="col-12 col-md-3 col-lg-3 sidebar" style={{paddingLeft:'30px',borderLeft:'1px solid #ccc'}}>
<a href="https://global.staffwiz.com/staffwiz-opt-in" alt="" target="_blank">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/bl4.png"
                                    ></img></a>
                                    {/* <p>Please note: The information in this article is not financial advice and is for informational purposes only. Some of the products or services featured on our website include affiliate links that may compensate us, at no extra cost to you, if you decide to make a purchase.</p> */}
                                    <h4 style={{padding:'20px 0px',textDecoration:'underline'}}>Recent Posts</h4>
                                    {Array.isArray(blogdetail) && blogdetail.slice(0, 5).map((data) => (
    <a key={data.id} href={data.slug} alt="">
        {decodeHtmlCharCodes(data.title.rendered)}
    </a>
))}
                                    {/* <h4 style={{padding:'20px 0px',textDecoration:'underline'}}>Categories</h4>
<a href="/the-best-virtual-assistant-services-for-small-business-owners" alt="">Blog</a> 
<a href="/the-best-virtual-assistant-services-for-small-business-owners" alt="">Case Study</a> */}
</div> 
                    </div>
                </div>
            </section>
        </>
    )

}

export default CaseStudy;