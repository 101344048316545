import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from 'date-fns';
import {Helmet} from "react-helmet";
import "./index.css"

const DetailPage = (props) => {

    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [blogdata, setblogdata] = useState([]);
     const [showPopup, setShowPopup] = useState(false);
        const [formData, setFormData] = useState({
            name: "",
            email: "",
          });
        
          const [errors, setErrors] = useState({});
          const validateForm = () => {
            let newErrors = {};
        
            if (!formData.name.trim()) {
              newErrors.name = "Name is required";
            }
        
            if (!formData.email.trim()) {
              newErrors.email = "Email is required";
            } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
              newErrors.email = "Invalid email format";
            }
        
            setErrors(newErrors);
            return Object.keys(newErrors).length === 0; // Return true if no errors
          };
          const handleSubmit = (e) => {
            e.preventDefault();
            if (validateForm()) {
                window.open("https://global.staffwiz.com/video-page-page?email="+formData.email, "_blank"); // Opens link in a new tab
                setShowPopup(false);
            }
          };
        
          const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
          };

    const loadblogdata = async () => {
        let catagiorydata;

        const res = await fetch("https://www.sellmymiles.com/staffwiz/wp-json/wp/v2/posts?_embed&slug=" + params.name, {
            method: "GET",
            statusCode: 200,
            headers: {
                 'Content-Type':'application/json',
            },
        })
        let data = await res.json();
        if (data) {
            setblogdata(data);
            console.log(blogdata);
        }
    }

    useEffect(() => {
        loadblogdata();
        const timer = setTimeout(() => {
            setShowPopup(true);
          }, 5000); // Show after 10 seconds
      
         return () => clearTimeout(timer); // Cleanup timer
    }, []);

    const decodeHtmlCharCodes = (str) => {
        return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
            return String.fromCharCode(charCode);
        });
    }

    // Close popup when clicking outside
const handleOutsideClick = (event) => {
    if (event.target.classList.contains("popup-overlay")) {
      setShowPopup(false);
    }
  };

    return (
        <>
        
            {blogdata && blogdata.length ?
                <section className="blog-details"> 
                 <Helmet>
            <title>{decodeHtmlCharCodes(blogdata[0].title.rendered)} | StaffWiz</title>
            <meta name="description" content={decodeHtmlCharCodes(blogdata[0].title.rendered)} />
        </Helmet>
        {showPopup && (
        <div className="popup-overlay" onClick={handleOutsideClick}>
        <div className="popup-box">
          <div className="pop-left">
           <img className="img-fluid" src="assets/images/popImg.png"></img>
          </div>
          <div className="pop-right">
            <h1>Cut Labor Costs by 70%</h1>
            <h3>Without Adding Complexity</h3>
          <p>Discover the proven strategy <b>Fortune 500 companies</b> use to scale efficiently.</p>
          <ul>
            <li>Reduce costs while maintaining quality</li>
            <li>Leverage expert workforce solutions</li>
            <li>Scale faster with streamlined operations</li>
          </ul>
          <input type="text" name="name" value={formData.name}
            onChange={handleChange} className={errors.name ? "inpt error-border" : "inpt"} placeholder="Your Name*" />
          <input type="text" name="email" className={errors.email ? "inpt error-border" : "inpt"} value={formData.email}
            onChange={handleChange} placeholder="Your Company Email *" />
          <button className="close-btn" onClick={handleSubmit}>
           GET ACCESS NOW
          </button>
          </div>
        </div>
      </div>
      )}
                    <div className="container">
                        <div className="row">
                        <div className="col-12 col-md-9 col-lg-9">
                       
                        <h1>{decodeHtmlCharCodes(blogdata[0].title.rendered)}</h1>
                        <h6> {format(blogdata[0].date.split("T")[0], 'MMMM dd, yyyy')}</h6>
                        <img className="details-img" src={blogdata[0]._embedded['wp:featuredmedia']['0'].source_url}></img>
                        <div className="row mt-4">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="info-details ">
                                    <div dangerouslySetInnerHTML={{ __html: blogdata[0].content.rendered }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-3 sidebar" style={{paddingLeft:'30px',borderLeft:'1px solid #ccc'}}>
                                        <a href="https://global.staffwiz.com/staffwiz-opt-in" alt="" target="_blank"><img
                                        className="img-fluid"
                                        src="assets/images/bl2.png"
                                    ></img></a>
                                    {/* <p>Please note: The information in this article is not financial advice and is for informational purposes only. Some of the products or services featured on our website include affiliate links that may compensate us, at no extra cost to you, if you decide to make a purchase.</p> */}
                                    <h4 style={{padding:'20px 0px',textDecoration:'underline'}}>Recent Posts</h4>
                                    <a href="/the-best-virtual-assistant-services-for-small-business-owners" alt="">The Best Virtual Assistant Services for Small Business Owners | Save Time & Boost Productivity</a>
                                    <a href="/how-to-build-a-remote-team-for-your-startup-a-step-by-step-guide" alt="">How to Build a Remote Team for Your Startup: A Step-by-Step Guide</a>
                                    <a href="/why-24-7-answering-services-for-uninterrupted-customer-care-are-important" alt="">Why 24/7 Answering Services for Uninterrupted Customer Care Are Important</a>
                                    <a href="/tis-the-season-boost-your-year-end-sales-with-holiday-staffing-solutions" alt="">Tis the Season: Boost Your Year-End Sales with Holiday Staffing Solutions</a>
                                    <a href="/scaling-smart-with-flexible-staffing-unlocking-agility-and-growth" alt="">Scaling Smart with Flexible Staffing: Unlocking Agility and Growth</a>
                                    {/* <h4 style={{padding:'20px 0px',textDecoration:'underline'}}>Categories</h4>
<a href="/the-best-virtual-assistant-services-for-small-business-owners" alt="">Blog</a> 
<a href="/the-best-virtual-assistant-services-for-small-business-owners" alt="">Case Study</a> */}
</div> 
                    </div>
                    </div>
                </section> :
                null}

        </>
    );
};

export default DetailPage;